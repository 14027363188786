import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import useSearch from '@app/use-search'
import SearchInput from '../components/sanity-modules/search-input'
import SearchResults from '../components/search-results'
import LayoutWrapper from '@app/layout-wrapper'
import Modules from '../components/sanity-module'
import get from 'lodash/get'
import data from '@data/sanity/searchTemplate.json'
import Head from '@app/head'

const headerModules = get(data, `headerModules`, [])
const footerModules = get(data, `footerModules`, [])
const title = get(data, `title`, `Search`)

function Search(props) {
	const {query, setQuery} = useSearch()

	// Set query from URL
	useEffect(() => {
		if(props.query !== query){
			setQuery(props.query)
		}
	}, [query, props.query])

	return (
		<>
			<Head title={title} />
			<Modules
				pageTitle={title}
				modules={headerModules}
			/>
			<LayoutWrapper>
				<SearchInput />
				<SearchResults />
			</LayoutWrapper>
			<Modules
				pageTitle={title}
				modules={footerModules}
			/>
		</>
	)
}



export default function SearchPage() {
	return (
		<Router>
			<Search path='/search' />
			<Search path='/search/:query' />
		</Router>
	)
}