import React, { useState } from 'react'
import useSearch from '@app/use-search'
import Link from '@app/link'
import { css } from '@emotion/core'
import get from 'lodash/get'
import searchTemplate from '@data/sanity/searchTemplate.json'

const resultsPerPage = get(searchTemplate, `resultsPerPage`, 20)

export default function SearchResults(){
	const [limit, setLimit] = useState(resultsPerPage)
	const { results, hasResults, query } = useSearch()

	// let fakeResults = []
	// for(let i = 20; i--;){
	// 	fakeResults[i] = {
	// 		title: `Title ${i}`,
	// 		slug: `/`,
	// 		excerpt: `Excerpt`,
	// 	}
	// }
	// results = fakeResults

	const shownResults = [...results]
	shownResults.length = limit

	const allVisible = shownResults.length >= results.length

	function loadMore(e){
		e.preventDefault()
		let newLimit = limit + resultsPerPage
		if(newLimit > results.length){
			newLimit = results.length
		}
		setLimit(newLimit)
	}

	return(
		<div>
			{!hasResults && query && (
				<div>No results found for "{query}"!</div>
			)}
			{hasResults && (
				<>
					<h1>Results for "{query}"</h1>
					<ul css={styles.list}>
						{shownResults.map((result, index) => {
							return (
								<li key={index}>
									<div css={styles.title}>
										<Link to={`/${result.slug}`}>
											{result.title}
										</Link>
									</div>
									<div>{result.excerpt}</div>
								</li>
							)
						})}
					</ul>
					{!allVisible && (
						<div css={styles.buttonContainer}>
							<button onClick={loadMore}>Load More</button>
						</div>
					)}
				</>
			)}
		</div>
	)
}

const styles = {
	buttonContainer: css`
		text-align: center;
	`,
	list: css`
		list-style-type: none;
		margin: 0;
		padding: 0;
		> li{
			margin: 20px 0;
		}
	`,
	title: css`
		font-weight: bold;
		font-size: 1.1em;
	`,
}